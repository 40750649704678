//@flow

export const swInitialState = {
    sw: { serviceWorkerInitialized: false, serviceWorkerUpdated: true, serviceWorkerRegistration: null }
}

type State = {
    +sw: {
        +serviceWorkerInitialized: boolean,
        +serviceWorkerUpdated: boolean,
        +serviceWorkerRegistration: any
    }
}

type Action = {
    error: string,
    payload: any
}

export const swActions = {
    swInit: (state:State) => {
        return { sw: { 
            serviceWorkerInitialized: true 
        }};
    },
    swUpdate: (state:State,action:Action) => {
        return { sw: { serviceWorkerUpdated: true, serviceWorkerRegistration: action.payload } };
    }
};