//@flow
import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#CA9A2C',
      },
      secondary: {
        main: '#F3C817',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#fff',
      },
    },
  });
  
  export default theme
