//@flow
import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(9, 0, 1),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    }
}));

export default function Spinner(){
    const classes = useStyles()

    return (
        <React.Fragment>
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                    Loading...
                    </Typography>
                    <CircularProgress disableShrink />
                </Container>
            </div>
        </React.Fragment>
    )
}