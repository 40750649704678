//@flow
import React,{Suspense,lazy} from 'react';
import { Switch, Route, BrowserRouter as Router  } from 'react-router-dom'
import AppHeader from './components/top-level/AppHeader'
import AppFooter from './components/top-level/AppFooter'
import Spinner from './components/top-level/Spinner'
import Analytics from 'react-router-ga'

const Landing = lazy(()=> import('./components/Landing'))
const NotFound = lazy(()=> import('./components/other/NotFound'))
const DataExtractionProjects = lazy(()=> import('./components/portfolios/DataExtractionProjects'))
const PrivacyPolicy = lazy(()=> import('./components/PrivacyPolicy'))
const TermOfService = lazy(()=> import('./components/TermOfService'))

function App() {
  return (
    <Router>
      <React.Fragment>
        <AppHeader />
        <main>
          <Analytics id="UA-93381200-6">
            <Suspense fallback={<Spinner />}> 
              <Switch>
                <Route path="/" exact={true} component={Landing} />
                <Route path="/dataExtractionProjects" exact={true} component={DataExtractionProjects} />
                <Route path="/privacy" exact={true} component={PrivacyPolicy} />
                <Route path="/termOfService" exact={true} component={TermOfService} />
                <Route >
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>
          </Analytics>
        </main>
        <AppFooter />
      </React.Fragment>
    </Router>
  );
}

export default App;
