//@flow
import React from 'react'
import Typography from '@material-ui/core/Typography'
import UILink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <UILink color="inherit" href="https://gigsdata.com">
            LESOFI ASIA
        </UILink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const linkStyle = {
  textDecoration: 'none',
  "&:hover": {
    textDecoration: 'none',  
  }
}

function PrivacyAndTermLink(){
  const classes = useStyles()

  return (
    <div className={classes.privacyRoot}>
      <Grid container spacing={1}  >
        <Grid item xs={12}  >
          <Link to="/privacy" style={linkStyle}>
            <Typography variant="body2" color="textSecondary" align="center" >
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12}  >
          <Link to="/termOfService" style={linkStyle}>
            <Typography variant="body2" color="textSecondary" align="center">
              Term of Service
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
    },
    privacyRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
    },
  },
}));

export default function AppFooter(){
    const classes = useStyles()
    return (
      <footer className={classes.footer}>
        <Copyright />
        <PrivacyAndTermLink />
      </footer>
    )
}