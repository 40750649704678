//@flow
import * as React from 'react'
import { swInitialState,swActions } from './sw'

const initialState= {
    ...swInitialState
};

export const StoreContext: any = React.createContext(initialState)

const Actions = {
    ...swActions
};

const reducer = (state, action) => {
    const act = Actions[action.type];
    const update = act(state,action);
    return { ...state, ...update };
};

type Props = {
    children: React.Node,
}

export const StoreProvider = (props: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    return (
        <StoreContext.Provider value={{ state, dispatch}}>
            {props.children}
        </StoreContext.Provider>
    );
};

export const useStore = (store:any) => {
    const { state, dispatch } = React.useContext(StoreContext);
    return { state, dispatch }
}