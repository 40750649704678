//@flow
import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import DataUsageIcon from '@material-ui/icons/DataUsageRounded'
import { Link } from 'react-router-dom'
import MaterialLink from '@material-ui/core/Link'

const useStyles = makeStyles((theme)=>({
    appBar: {
        backgroundColor: '#3E414A',
        color: '#F3C817'
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: '#F3C817',
        textDecoration: 'none',
        "&:hover": {
          color: '#F3C817',
          textDecoration: 'none',  
        }
    },
}))

export default function AppHeader(){
    const classes = useStyles();
    
    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar >
            <DataUsageIcon className={classes.icon} />
            <Typography variant="h6" className={classes.title} component={Link} to='/'>
              Crowdsource by Gigsdata.com
            </Typography>
            <MaterialLink underline="none" target="_blank" rel="noreferrer" href="https://crowd.gigsdata.com">
              Crowd Worker
            </MaterialLink>
          </Toolbar>
        </AppBar>
      </div>
    )
}